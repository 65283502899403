<template>
  <div class="box"> 
    <el-row :gutter="4" class="mt15" style="display: flex;padding: 10px 0 0 10px">
        <el-col :span="3">
          <el-select style="width: 100%" v-model="querayObj.status" size="small"  class="placeInput"  :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.WorkOrderStatus')" >
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        </el-col>
        <select-input :options="options1" @selectSearchChange="selectSearchChange" @searchList="searchList"></select-input>
    </el-row>
    <div class="tablebox">
      <el-table :cell-style="{padding:'3px 0px'}" :header-row-style="{height:'30px'}" size="mini" stripe ref="multipleTable" :data="tableData" class="tableClass" tooltip-effect="dark" style="width: 100%;" :default-sort="{ prop: 'CREATE_TIME', order: 'ascending' }" @selection-change="handleSelectionChange" @sort-change="sort">
        <el-table-column :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.WorkOrderNumber')" width="180" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.problemCode }}</template>
        </el-table-column>
        <el-table-column prop="problemName" :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemName')" width="180" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="DEVICE_NAME" :label="$t('public.DeviceName')" width="180" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.deviceName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="USER_NAME" :label="$t('public.Username')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <div>
              {{ scope.row.loginName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="GROUP_FULL_NAME" :label="$t('public.Section')" :show-overflow-tooltip="true">
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex)}}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="status" :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.status')" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span type="text" style="color: #606266; cursor: auto;font-size: 12px;" v-if="scope.row.status == '0'">{{$t('MobileOperations.WorkOderManagement.WorkOrderList.ToBeProcessed')}}</span>
            <span type="text" style="color: #606266; cursor: auto;font-size: 12px;" v-if="scope.row.status == '1'">{{$t('MobileOperations.WorkOderManagement.WorkOrderList.processed')}}</span>
          </template>
        </el-table-column>
        <el-table-column width="180" prop="CREATE_TIME" :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.SubmissionTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.createTime | dateformat
          }}</template>
        </el-table-column>
        <el-table-column width="180" prop="UPDATE_TIME" :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.UpdateTime')" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{
            scope.row.updateTime | dateformat
          }}</template>
        </el-table-column>
        <!-- -------------- -->
        <el-table-column :label="$t('public.Controls')">
          <template v-slot="{ row }">
            <span style="color: #d38a08;cursor: pointer;" v-if="row.status === '0'" @click="chuli(row, false)" type="text" size="small">{{$t('MobileOperations.WorkOderManagement.WorkOrderList.handle')}}</span>
            <span style="color: #d38a08;cursor: pointer;" v-if="row.status !== '0'" @click="xiangqing(row, true)" type="text" size="small">{{$t('MobileOperations.WorkOderManagement.WorkOrderList.details')}}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination :page="page.currentPage" :limit="page.pageSize" :total="page.total" :pageSizes="[10, 20, 30, 40]" v-on:handleChildGetList="handleParentGetList"></pagination>
    </div>
    <Eldialog @handleClose="handleClose" :title="title" :dialogVisible="outerVisibleChu" @heightCustom="heightCustom" :flagbtn="true" :cancelBtn="true" :determineBtn="true" @determine="determine" :num="'470px'">
      <el-form :rules="rules1" class="detailContent" :model="tableDict" ref="ruleForm1" label-width="150px">
        <div v-if="ming[4]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo5" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img5_1" style="width: 50px;margin:0 5px;" :src="tableDict.img5_1" @click="imgbigClick(tableDict.img5_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_2" style="width: 50px;margin:0 5px;" :src="tableDict.img5_2" @click="imgbigClick(tableDict.img5_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_3" style="width: 50px;margin:0 5px;" :src="tableDict.img5_3" @click="imgbigClick(tableDict.img5_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_4" style="width: 50px;margin:0 5px;" :src="tableDict.img5_4" @click="imgbigClick(tableDict.img5_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result5">
            <el-input maxlength="300" show-word-limit v-model="tableDict.result5" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[3]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo4" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img4_1" style="width: 50px;margin:0 5px;" :src="tableDict.img4_1" @click="imgbigClick(tableDict.img4_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_2" style="width: 50px;margin:0 5px;" :src="tableDict.img4_2" @click="imgbigClick(tableDict.img4_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_3" style="width: 50px;margin:0 5px;" :src="tableDict.img4_3" @click="imgbigClick(tableDict.img4_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_4" style="width: 50px;margin:0 5px;" :src="tableDict.img4_4" @click="imgbigClick(tableDict.img4_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result4">
            <el-input maxlength="300" show-word-limit :disabled="flagStatus5" v-model="tableDict.result4" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[2]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo3" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img3_1" style="width: 50px;margin:0 5px;" :src="tableDict.img3_1" @click="imgbigClick(tableDict.img3_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_2" style="width: 50px;margin:0 5px;" :src="tableDict.img3_2" @click="imgbigClick(tableDict.img3_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_3" style="width: 50px;margin:0 5px;" :src="tableDict.img3_3" @click="imgbigClick(tableDict.img3_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_4" style="width: 50px;margin:0 5px;" :src="tableDict.img3_4" @click="imgbigClick(tableDict.img3_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result3">
            <el-input maxlength="300" show-word-limit :disabled="flagStatus4" v-model="tableDict.result3" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[1]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo2" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img2_1" style="width: 50px;margin:0 5px;" :src="tableDict.img2_1" @click="imgbigClick(tableDict.img2_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_2" style="width: 50px;margin:0 5px;" :src="tableDict.img2_2" @click="imgbigClick(tableDict.img2_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_3" style="width: 50px;margin:0 5px;" :src="tableDict.img2_3" @click="imgbigClick(tableDict.img2_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_4" style="width: 50px;margin:0 5px;" :src="tableDict.img2_4" @click="imgbigClick(tableDict.img2_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result2">
            <el-input maxlength="300" show-word-limit :disabled="flagStatus3" v-model="tableDict.result2" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemNameContent')">
            <el-input :disabled="true" v-model="detailData.problemName" style="width: 400px" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseEnterContent')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ApplicationName')">
            <el-input :disabled="true" v-model="detailData.appName" style="width: 400px" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseEnterContent')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseDescriptionOfTheProblem')" v-model="tableDict.problemMemo" maxlength="300" show-word-limit style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img1_1" style="width: 50px;margin:0 5px;" :src="tableDict.img1_1" :z-index='200' @click="imgbigClick(tableDict.img1_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_2" style="width: 50px;margin:0 5px;" :src="tableDict.img1_2" @click="imgbigClick(tableDict.img1_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_3" style="width: 50px;margin:0 5px;" :src="tableDict.img1_3" @click="imgbigClick(tableDict.img1_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_4" style="width: 50px;margin:0 5px;" :src="tableDict.img1_4" @click="imgbigClick(tableDict.img1_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result">
            <el-input :disabled="flagStatus2" v-model="tableDict.result" maxlength="300" show-word-limit :rows="4" type="textarea" style="width: 400px; margin-right: 5px"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </Eldialog>
    <!-- 详情 -->
    <Eldialog @handleClose="handleClose2" :title="title" :dialogVisible="dialogVisible" @heightCustom="heightCustom" :num="'470px'">
      <el-form :rules="rules1" class="detailContent" :model="tableDict" ref="ruleForm1" label-width="150px">
        <div v-if="ming[4]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo5" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img5_1" style="width: 50px;margin:0 5px;" :src="tableDict.img5_1" @click="imgbigClick(tableDict.img5_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_2" style="width: 50px;margin:0 5px;" :src="tableDict.img5_2" @click="imgbigClick(tableDict.img5_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_3" style="width: 50px;margin:0 5px;" :src="tableDict.img5_3" @click="imgbigClick(tableDict.img5_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img5_4" style="width: 50px;margin:0 5px;" :src="tableDict.img5_4" @click="imgbigClick(tableDict.img5_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result5">
            <el-input :disabled="true" v-model="tableDict.result5" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[3]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo4" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img4_1" style="width: 50px;margin:0 5px;" :src="tableDict.img4_1" @click="imgbigClick(tableDict.img4_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_2" style="width: 50px;margin:0 5px;" :src="tableDict.img4_2" @click="imgbigClick(tableDict.img4_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_3" style="width: 50px;margin:0 5px;" :src="tableDict.img4_3" @click="imgbigClick(tableDict.img4_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img4_4" style="width: 50px;margin:0 5px;" :src="tableDict.img4_4" @click="imgbigClick(tableDict.img4_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result4">
            <el-input :disabled="true" v-model="tableDict.result4" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[2]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo3" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img3_1" style="width: 50px;margin:0 5px;" :src="tableDict.img3_1" @click="imgbigClick(tableDict.img3_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_2" style="width: 50px;margin:0 5px;" :src="tableDict.img3_2" @click="imgbigClick(tableDict.img3_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_3" style="width: 50px;margin:0 5px;" :src="tableDict.img3_3" @click="imgbigClick(tableDict.img3_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img3_4" style="width: 50px;margin:0 5px;" :src="tableDict.img3_4" @click="imgbigClick(tableDict.img3_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result3">
            <el-input :disabled="true" v-model="tableDict.result3" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div v-if="ming[1]">
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" v-model="tableDict.problemMemo2" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img2_1" style="width: 50px;margin:0 5px;" :src="tableDict.img2_1" @click="imgbigClick(tableDict.img2_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_2" style="width: 50px;margin:0 5px;" :src="tableDict.img2_2" @click="imgbigClick(tableDict.img2_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_3" style="width: 50px;margin:0 5px;" :src="tableDict.img2_3" @click="imgbigClick(tableDict.img2_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img2_4" style="width: 50px;margin:0 5px;" :src="tableDict.img2_4" @click="imgbigClick(tableDict.img2_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result2">
            <el-input :disabled="true" v-model="tableDict.result2" type="textarea" style="width: 400px"></el-input>
          </el-form-item>
          <p style="margin-left: 75px;margin-top:6px;margin-bottom:10px;">
            ---------------------------------------------------------------------------------
          </p>
        </div>
        <div>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemNameContent')">
            <el-input :disabled="true" v-model="detailData.problemName" style="width: 400px" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseEnterContent')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ApplicationName')">
            <el-input :disabled="true" v-model="detailData.appName" style="width: 400px" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseEnterContent')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.ProblemDescription')">
            <el-input :disabled="true" type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" :placeholder="$t('MobileOperations.WorkOderManagement.WorkOrderList.PleaseDescriptionOfTheProblem')" v-model="tableDict.problemMemo" maxlength="300" show-word-limit style="width: 400px"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.picture')">
            <el-image v-if="tableDict.img1_1" style="width: 50px;margin:0 5px;" :src="tableDict.img1_1" @click="imgbigClick(tableDict.img1_1)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_2" style="width: 50px;margin:0 5px;" :src="tableDict.img1_2" @click="imgbigClick(tableDict.img1_2)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_3" style="width: 50px;margin:0 5px;" :src="tableDict.img1_3" @click="imgbigClick(tableDict.img1_3)" :preview-src-list="srcList">
            </el-image>
            <el-image v-if="tableDict.img1_4" style="width: 50px;margin:0 5px;" :src="tableDict.img1_4" @click="imgbigClick(tableDict.img1_4)" :preview-src-list="srcList">
            </el-image>
          </el-form-item>
          <el-form-item :label="$t('MobileOperations.WorkOderManagement.WorkOrderList.HandlingSuggestion')" prop="result">
            <el-input :disabled="true" v-model="tableDict.result" maxlength="300" show-word-limit :rows="4" type="textarea" style="width: 400px; margin-right: 5px"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </Eldialog>
    <!-- 提示消息组件 -->
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" @determine="tishi1" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import selectInput from "@/components/selectInput"
import naviGation from '@/components/hearder/index'
import dialogInfo from '@/components/promptMessage/index'
import Eldialog from '@/components/elDialog'
import pagination from '../../components/pagination/page.vue'
import base64 from 'js-base64'
const Base64 = require('js-base64').Base64
export default {
  components: {
    selectInput,
    pagination,
    naviGation,
    dialogInfo,
    Eldialog
  },
  data() {
    return {
      groupFullNameShowByIndex: '', //用户部门截取的下标
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
      ],
      imGtitle: '',
      imgFlag: false,
      imgUrlBig: '',
      flagStatus2: false,
      flagStatus3: false,
      flagStatus4: false,
      flagStatus5: false,
      imgUrl: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
      rules1: {
        result5: [
          { required: true, message: '处理意见不能为空', trigger: 'blur' },
          {
            min: 1,
            max: 300,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        result4: [
          { required: true, message: '处理意见不能为空', trigger: 'blur' },
          {
            min: 1,
            max: 300,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        result3: [
          { required: true, message: '处理意见不能为空', trigger: 'blur' },
          {
            min: 1,
            max: 300,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        result2: [
          { required: true, message: '处理意见不能为空', trigger: 'blur' },
          {
            min: 1,
            max: 300,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ],
        result: [
          { required: true, message: '处理意见不能为空', trigger: 'blur' },
          {
            min: 1,
            max: 300,
            message: '长度在 1 到 30 个字符',
            trigger: 'blur'
          }
        ]
      },
      dataLength: {
        img: ''
      },
      selectVal:{
        deviceName:'',
        InputText:""
      },
      title: this.$t('MobileOperations.WorkOderManagement.WorkOrderList.WorkOrderDetails'),
      tupian: '',
      tupian1: '',
      two: {},
      ming: [],
      titleInfo: '',
      visibaelFlag: false,
      dialogVisible: false,
      flagmm: true,
      placeholder: '工单编号',
      
      outerVisible: false,// 是否打开详情
      outerVisibleChu: false,
      tableDict: {
        problemMemo: '',
        result: '',
        img: ''
      },
      detailData: {
        fileContent: ''
      }, //详情data
      detailDatas: {}, //详情data
      dataFrom: {}, //工单详情弹框
      // 遮罩全选
      isCheckShow: false,
      // 分页
      page: {
        pageSize: 10, //每页条数,  默认10条
        total: 0, //总条数
        // totalPages: 40, //总页数
        currentPage: 1,
        orderColume: 'CREATE_TIME', //排序字段
        orderRule: 'DESC' //排序规则ASC、DESC
      },
      querayObj: { 
        input: '',
        hh: '状态',
        pageSize: 5,
        currentPage: 1,
        problemCode: '',
        askName: '',
        selectStatus: '工单编号'
      },
      
      options: [// 状态
        {
          value: '0',
          label: '待处理'
        },
        {
          value: '1',
          label: '已处理'
        }
      ],
      options1: [
        {
          value: '0',
          label: '工单编号'
        },
        {
          value: '1',
          label: '问题名称'
        }
      ],
      value: '', 
      input: '', // input模糊查询
      tableData: [],// table

      problemCode: '', 
      workId: '2',// 获取内容
      work: [
        {
          workId: '',
          problemId: '',
          result: ''
        }
      ],
      result: null,
      desc: null,
      detailId: '',
      delarr: [], //存放要删除的数据
      ids: ''
    }
  },
  created() {
    this.tablelist()
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      'groupFullNameShowByIndex'
    )
  },
  methods: {
    selectSearchChange(val){

    },
    imgbigClick(url) {
      this.srcList = []
      this.srcList.push(url)
    },
    
    chuli(val, flag) {// 处理
      this.title = '工单处理'
      this.wenti(val.id, true)
      this.outerVisibleChu = true
      this.flag = flag
      this.detailData = { ...val }
      this.detailData['decideStr'] = eval(this.detailData.decide)
      this.tableDict = {}
    },
    async wenti(id, flag) {
      var param = {
        workId: id
      }
      const res = await this.$axios.post(
        '/httpServe/workOrder/getProblemDataInfo',
        param,
        true
      ) 
      this.ids = res.data[0].id
      this.ming = res.data
      let newData = res.data.sort(function (a, b) {
        return a.id - b.id
      }) 
      if (newData.length == 1) {
        if (flag) {
          this.flagStatus2 = false
        }
        this.$set(this.tableDict, 'problemMemo', res.data[0].problemMemo)
        this.$set(this.tableDict, 'result', newData[0].result)
        if (newData[0].fileContent[0]) {
          this.downline(
            newData[0].fileContent[0].path,
            this.tableDict,
            'img1_1'
          )
        }
        if (newData[0].fileContent[1]) {
          this.downline(
            newData[0].fileContent[1].path,
            this.tableDict,
            'img1_2'
          )
        }
        if (newData[0].fileContent[2]) {
          this.downline(
            newData[0].fileContent[2].path,
            this.tableDict,
            'img1_3'
          )
        }
        if (newData[0].fileContent[3]) {
          this.downline(
            newData[0].fileContent[3].path,
            this.tableDict,
            'img1_4'
          )
        }
      } else if (newData.length == 2) {
        if (flag) {
          this.flagStatus2 = true
        }
        this.$set(this.tableDict, 'problemMemo', res.data[0].problemMemo)
        if (newData[0].fileContent[0]) {
          this.downline(
            newData[0].fileContent[0].path,
            this.tableDict,
            'img1_1'
          )
        }
        if (newData[0].fileContent[1]) {
          this.downline(
            newData[0].fileContent[1].path,
            this.tableDict,
            'img1_2'
          )
        }
        if (newData[0].fileContent[2]) {
          this.downline(
            newData[0].fileContent[2].path,
            this.tableDict,
            'img1_3'
          )
        }
        if (newData[0].fileContent[3]) {
          this.downline(
            newData[0].fileContent[3].path,
            this.tableDict,
            'img1_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo2', newData[1].problemMemo)
        this.$set(this.tableDict, 'result2', newData[1].result)
        this.$set(this.tableDict, 'result', newData[0].result)
        if (newData[1].fileContent[0]) {
          this.downline(
            newData[1].fileContent[0].path,
            this.tableDict,
            'img2_1'
          )
        }
        if (newData[1].fileContent[1]) {
          this.downline(
            newData[1].fileContent[1].path,
            this.tableDict,
            'img2_2'
          )
        }
        if (newData[1].fileContent[2]) {
          this.downline(
            newData[1].fileContent[2].path,
            this.tableDict,
            'img2_3'
          )
        }
        if (newData[1].fileContent[3]) {
          this.downline(
            newData[1].fileContent[3].path,
            this.tableDict,
            'img2_4'
          )
        }
      } else if (newData.length == 3) {
        if (flag) {
          this.flagStatus2 = true
          this.flagStatus3 = true
        }
        this.$set(this.tableDict, 'problemMemo', res.data[0].problemMemo)
        if (newData[0].fileContent[0]) {
          this.downline(
            newData[0].fileContent[0].path,
            this.tableDict,
            'img1_1'
          )
        }
        if (newData[0].fileContent[1]) {
          this.downline(
            newData[0].fileContent[1].path,
            this.tableDict,
            'img1_2'
          )
        }
        if (newData[0].fileContent[2]) {
          this.downline(
            newData[0].fileContent[2].path,
            this.tableDict,
            'img1_3'
          )
        }
        if (newData[0].fileContent[3]) {
          this.downline(
            newData[0].fileContent[3].path,
            this.tableDict,
            'img1_4'
          )
        }
        this.$set(this.tableDict, 'result', newData[0].result)
        if (newData[1].fileContent[0]) {
          this.downline(
            newData[1].fileContent[0].path,
            this.tableDict,
            'img2_1'
          )
        }
        if (newData[1].fileContent[1]) {
          this.downline(
            newData[1].fileContent[1].path,
            this.tableDict,
            'img2_2'
          )
        }
        if (newData[1].fileContent[2]) {
          this.downline(
            newData[1].fileContent[2].path,
            this.tableDict,
            'img2_3'
          )
        }
        if (newData[1].fileContent[3]) {
          this.downline(
            newData[1].fileContent[3].path,
            this.tableDict,
            'img2_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo2', newData[1].problemMemo)
        this.$set(this.tableDict, 'result2', newData[1].result)
        if (newData[2].fileContent[0]) {
          this.downline(
            newData[2].fileContent[0].path,
            this.tableDict,
            'img3_1'
          )
        }
        if (newData[2].fileContent[1]) {
          this.downline(
            newData[2].fileContent[1].path,
            this.tableDict,
            'img3_2'
          )
        }
        if (newData[2].fileContent[2]) {
          this.downline(
            newData[2].fileContent[2].path,
            this.tableDict,
            'img3_3'
          )
        }
        if (newData[2].fileContent[3]) {
          this.downline(
            newData[2].fileContent[3].path,
            this.tableDict,
            'img3_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo3', newData[2].problemMemo)
        this.$set(this.tableDict, 'result3', newData[2].result)
      } else if (newData.length == 4) {
        if (flag) {
          this.flagStatus2 = true
          this.flagStatus3 = true
          this.flagStatus4 = true
        }
        this.$set(this.tableDict, 'problemMemo', res.data[0].problemMemo)
        if (newData[0].fileContent[0]) {
          this.downline(
            newData[0].fileContent[0].path,
            this.tableDict,
            'img1_1'
          )
        }
        if (newData[0].fileContent[1]) {
          this.downline(
            newData[0].fileContent[1].path,
            this.tableDict,
            'img1_2'
          )
        }
        if (newData[0].fileContent[2]) {
          this.downline(
            newData[0].fileContent[2].path,
            this.tableDict,
            'img1_3'
          )
        }
        if (newData[0].fileContent[3]) {
          this.downline(
            newData[0].fileContent[3].path,
            this.tableDict,
            'img1_4'
          )
        }
        this.$set(this.tableDict, 'result', newData[0].result)
        if (newData[1].fileContent[0]) {
          this.downline(
            newData[1].fileContent[0].path,
            this.tableDict,
            'img2_1'
          )
        }
        if (newData[1].fileContent[1]) {
          this.downline(
            newData[1].fileContent[1].path,
            this.tableDict,
            'img2_2'
          )
        }
        if (newData[1].fileContent[2]) {
          this.downline(
            newData[1].fileContent[2].path,
            this.tableDict,
            'img2_3'
          )
        }
        if (newData[1].fileContent[3]) {
          this.downline(
            newData[1].fileContent[3].path,
            this.tableDict,
            'img2_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo2', newData[1].problemMemo)
        this.$set(this.tableDict, 'result2', newData[1].result)
        if (newData[2].fileContent[0]) {
          this.downline(
            newData[2].fileContent[0].path,
            this.tableDict,
            'img3_1'
          )
        }
        if (newData[2].fileContent[1]) {
          this.downline(
            newData[2].fileContent[1].path,
            this.tableDict,
            'img3_2'
          )
        }
        if (newData[2].fileContent[2]) {
          this.downline(
            newData[2].fileContent[2].path,
            this.tableDict,
            'img3_3'
          )
        }
        if (newData[2].fileContent[3]) {
          this.downline(
            newData[2].fileContent[3].path,
            this.tableDict,
            'img3_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo3', newData[2].problemMemo)
        this.$set(this.tableDict, 'result3', newData[2].result)
        if (newData[3].fileContent[0]) {
          this.downline(
            newData[3].fileContent[0].path,
            this.tableDict,
            'img4_1'
          )
        }
        if (newData[3].fileContent[1]) {
          this.downline(
            newData[3].fileContent[1].path,
            this.tableDict,
            'img4_2'
          )
        }
        if (newData[3].fileContent[2]) {
          this.downline(
            newData[3].fileContent[2].path,
            this.tableDict,
            'img4_3'
          )
        }
        if (newData[3].fileContent[3]) {
          this.downline(
            newData[3].fileContent[3].path,
            this.tableDict,
            'img4_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo4', newData[3].problemMemo)
        this.$set(this.tableDict, 'result4', newData[3].result)
      } else if (newData.length == 5) {
        if (flag) {
          this.flagStatus2 = true
          this.flagStatus3 = true
          this.flagStatus4 = true
          this.flagStatus5 = true
        }
        this.$set(this.tableDict, 'problemMemo', res.data[0].problemMemo)
        if (newData[0].fileContent[0]) {
          this.downline(
            newData[0].fileContent[0].path,
            this.tableDict,
            'img1_1'
          )
        }
        if (newData[0].fileContent[1]) {
          this.downline(
            newData[0].fileContent[1].path,
            this.tableDict,
            'img1_2'
          )
        }
        if (newData[0].fileContent[2]) {
          this.downline(
            newData[0].fileContent[2].path,
            this.tableDict,
            'img1_3'
          )
        }
        if (newData[0].fileContent[3]) {
          this.downline(
            newData[0].fileContent[3].path,
            this.tableDict,
            'img1_4'
          )
        }
        this.$set(this.tableDict, 'result', newData[0].result)
        if (newData[1].fileContent[0]) {
          this.downline(
            newData[1].fileContent[0].path,
            this.tableDict,
            'img2_1'
          )
        }
        if (newData[1].fileContent[1]) {
          this.downline(
            newData[1].fileContent[1].path,
            this.tableDict,
            'img2_2'
          )
        }
        if (newData[1].fileContent[2]) {
          this.downline(
            newData[1].fileContent[2].path,
            this.tableDict,
            'img2_3'
          )
        }
        if (newData[1].fileContent[3]) {
          this.downline(
            newData[1].fileContent[3].path,
            this.tableDict,
            'img2_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo2', newData[1].problemMemo)
        this.$set(this.tableDict, 'result2', newData[1].result)
        if (newData[2].fileContent[0]) {
          this.downline(
            newData[2].fileContent[0].path,
            this.tableDict,
            'img3_1'
          )
        }
        if (newData[2].fileContent[1]) {
          this.downline(
            newData[2].fileContent[1].path,
            this.tableDict,
            'img3_2'
          )
        }
        if (newData[2].fileContent[2]) {
          this.downline(
            newData[2].fileContent[2].path,
            this.tableDict,
            'img3_3'
          )
        }
        if (newData[2].fileContent[3]) {
          this.downline(
            newData[2].fileContent[3].path,
            this.tableDict,
            'img3_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo3', newData[2].problemMemo)
        this.$set(this.tableDict, 'result3', newData[2].result)
        if (newData[3].fileContent[0]) {
          this.downline(
            newData[3].fileContent[0].path,
            this.tableDict,
            'img4_1'
          )
        }
        if (newData[3].fileContent[1]) {
          this.downline(
            newData[3].fileContent[1].path,
            this.tableDict,
            'img4_2'
          )
        }
        if (newData[3].fileContent[2]) {
          this.downline(
            newData[3].fileContent[2].path,
            this.tableDict,
            'img4_3'
          )
        }
        if (newData[3].fileContent[3]) {
          this.downline(
            newData[3].fileContent[3].path,
            this.tableDict,
            'img4_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo4', newData[3].problemMemo)
        this.$set(this.tableDict, 'result4', newData[3].result)
        if (newData[4].fileContent[0]) {
          this.downline(
            newData[4].fileContent[0].path,
            this.tableDict,
            'img5_1'
          )
        }
        if (newData[4].fileContent[1]) {
          this.downline(
            newData[4].fileContent[1].path,
            this.tableDict,
            'img5_2'
          )
        }
        if (newData[4].fileContent[2]) {
          this.downline(
            newData[4].fileContent[2].path,
            this.tableDict,
            'img5_3'
          )
        }
        if (newData[4].fileContent[3]) {
          this.downline(
            newData[4].fileContent[3].path,
            this.tableDict,
            'img5_4'
          )
        }
        this.$set(this.tableDict, 'problemMemo5', newData[4].problemMemo)
        this.$set(this.tableDict, 'result5', newData[4].result)
      }
    },
    
    async downline(path, data, img) {// 下载模板接口处理图片函数
      var param = {
        type: 'protected',
        path: path
      }
      const res = await this.$axios.post(
        '/httpServe/sysFile/fileDown',
        param,
        true
      )
      let url = Base64.decode(res.data)
      url = this.imgUrl + url
      this.$set(data, img, url)
    },
    
    sort(column) {//排序
      if (column.order === 'ascending') {
        this.page.orderRule = 'ASC'
      } else if (column.order === 'descending') {
        this.page.orderRule = 'DESC'
      }
      this.page.orderColume = column.prop
      this.tablelist()
    },
    
    async xiangqing(val) {// 详情
      this.detailData = val
      this.title = '工单详情'
      this.wenti(val.id, false)
      this.dialogVisible = true
    },
    changeName(item) {
      if (item === '问题名称') {
        this.flagmm = false
        this.querayObj.input = ''
      } else {
        this.flagmm = true
        this.querayObj.problemCode = ''
      }

      this.placeholder = item
    },
    
    handleSelectionChange(obj) {//表格当选择项发生变化时会触发该事件
      //this.multipleSelection = obj;
      this.delarr = obj
    },
    async searchList(val) {
      this.selectVal = val
      this.page.currentPage = 1
      var param = {
        orderColume: this.page.orderColume, //排序字段
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        problemName: val.deviceName == '1'?val.InputText:'', 
        status: this.querayObj.status,
        problemCode: val.deviceName == '0'?val.InputText:''
      } 
      const res = await this.$axios.post(
        '/httpServe/workOrder/getWorkDataInfo',
        param,
        true
      ) 
      this.tableData = res.data.content
      this.page.total = res.data.total
    },
    
    compare(property, desc) {// 正序、倒序
      return function (a, b) {
        var value1 = a[property]
        var value2 = b[property]
        if (desc == true) {
          // 升序排列
          return value1 - value2
        } else {
          // 降序排列
          return value2 - value1
        }
      }
    },
    tishi1() {
      this.visibaelFlag = false
    },
    // 高度
    heightCustom() {
      this.height.height = this.num
    },
    async tablelist(val) {
      var param = {
        orderColume: this.page.orderColume, //排序字段
        orderRule: this.page.orderRule, //排序规则ASC、DESC
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        problemName: val?val.deviceName == '1'?val.InputText:'': "", 
        status: this.querayObj.status,
        problemCode: val?val.deviceName == '0'?val.InputText:'': ""
      } 
      const res = await this.$axios.post(
        '/httpServe/workOrder/getWorkDataInfo',
        param,
        true
      ) 
      this.tableData = res.data.content
      this.page.total = res.data.total
    },
    comit() {
      this.updated()
    },
    
    async updated() {// 修改接口
      var param = {
        workId: this.detailData.id,
        problemId: this.ids,
        result: this.tableDict.result5
          ? this.tableDict.result5
          : this.tableDict.result4
          ? this.tableDict.result4
          : this.tableDict.result3
          ? this.tableDict.result3
          : this.tableDict.result2
          ? this.tableDict.result2
          : this.tableDict.result
      }
      const res = await this.$axios.post(
        '/httpServe/workOrder/updateProblemData',
        param,
        true
      ) 
      if (res.code === 200) {
        this.$message({
          message: this.$t('MobileOperations.WorkOderManagement.WorkOrderList.SubmitSuccessfully'),
          type: 'success',
          offset: 100
        }) 
        this.outerVisibleChu = false
        this.tablelist()
      }
    },
    
    async guifan() {// 获取规范类别
      var param = {
        currentPage: this.page.currentPage, //当前页
        pageSize: this.page.pageSize, //每页多少数据
        name: '' //标题
      }
      const res = await this.$axios.post(
        '/httpServe/standard/getDataInfo',
        param,
        true
      )
      this.tableData = res.data.content
    },
    
    maskLayer() {//全选遮罩层
      this.isCheckShow = true
    },
    
    leave() {// 鼠标移出弹框
      this.isCheckShow = false
    },
   
    curSelection() { //当前页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    
    toggleAllSelection() {//所有页全选
      this.disabled = false
      this.$refs.multipleTable.toggleAllSelection()
    },
    
    toggleSelection(rows) {//取消全选
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true
          })
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
        this.disabled = false
      }
      this.isCheckShow = true
    },
    
    handleParentGetList(page, limit) {// 响应分页组件查询事件
      this.page.currentPage = page
      this.page.pageSize = limit
      
      this.tablelist(this.selectVal)// 调用查询方法
    },
    determine() {
      this.$refs.ruleForm1.validate((valid) => {
        if (valid) {
          this.comit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleClose() {
      this.visibaelFlag = false
      this.outerVisibleChu = false
      this.tableDict=[]
    },
    handleClose2() {
      this.dialogVisible = false
      this.tableDict=[]
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  height: 100%;
}
::v-deep .el-image__error {
  display: none;
}
#detailBox .el-dialog__body {
  height: 681px;
  // margin-left: 50px;
}
// 处理按钮
.btn {
  width: 100%;
  height: 60px;
  line-height: 60px;
  padding-left: 15px;
  background: rgb(242, 246, 248);
  .el-button {
    // color: rgb(76, 184, 247);
    font-weight: 900;
  }
}
.tablebox {
  margin-right: 10px;
  position: relative;
  padding-left: 10px;
  padding-bottom: 50px;
}
//全选弹框
.tableCheckBox {
  border: 1px solid #1e89e5;
  border-radius: 3px;
  width: 80px;
  background-color: #fff;
  position: absolute;
  top: 30px;
  left: 1%;
  z-index: 999;
  // background:rgba(255, 255, 255,.1)
  li {
    margin-top: -1px;
    height: 30px;
    line-height: 30px;
    list-style: none;
    text-align: center;
    color: #575656;
    cursor: pointer;
    font-weight: 400;
    font-family: Microsoft YaHei;
    font-size: 12px;
    div {
      display: inline-block;
    }
  }
  li:hover {
    color: #d38a08;
    text-decoration: underline;
  }
}
.l1 {
  display: flex;
  margin-top: 30px;
}
.detailContent {
  margin-left: 0px;
}

.el-image-viewer__img {
  width: 200px !important;
}
.placeInput{
  /* ---------------------------------------注意区分组件 */
  ::v-deep .el-input .el-input__inner::placeholder {
   color: #606266 !important;
  }
  /* 谷歌 */
  ::v-deep .el-input .el-input__inner::-webkit-input-placeholder {
   color: #606266 !important;
  }
  /* 火狐::-moz-placeholder */
  ::v-deep .el-input .el-input__inner::-moz-placeholder {
   color: #606266 !important;
   opacity: 1 !important; /*火狐默认有opacity:0.4; */
  }
  /*ie*/
  ::v-deep .el-input .el-input__inner:-ms-input-placeholder {
   color: #606266 !important;
  }
}
</style>
<style lang="scss">
.el-image-viewer__img {
  width: 400px;
}
</style>
